<template>
  <div class="yanpian">
    <div class="tops">
      <p>订单号</p>
      <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_number" class="sr" placeholder="请输入内容"></el-input>
      <p class="xm">姓名</p>
      <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_name" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input>
      <el-button>同步数据</el-button>
    </div>
    <h3>参数</h3>
    <div style="display: flex;align-items: center;flex-wrap: wrap;">
      <div class="sr-content">
        <ul>
          <li></li>
          <li>基弧</li>
          <li>颜色</li>
          <li>厚度</li>
          <li>定位弧</li>
          <li>降幅</li>
        </ul>
        <ul>
          <li>OD</li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_JiHu_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_Color_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_Thickness_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_DCurve_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_Degrade_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul>
          <li>OS</li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_JiHu_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_Color_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_Thickness_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_DCurve_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
          <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_Degrade_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
      <span style="padding: 20px 30px 0 30px;color: blue;">+</span>
      <div class="dx">
        <div>
          <p>表面划痕</p>
          <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q1" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q1" label="0">否</el-radio>
        </div>
        <div>
          <p>边缘缺损</p>
          <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q3" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q3" label="0">否</el-radio>
        </div>
      </div>
      <span style="padding: 20px 30px 0 30px;color: blue;">+</span>
      <div class="dx">
        <div>
          <p>表面光滑</p>
          <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q2" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q2" label="0">否</el-radio>
        </div>
        <div>
          <p>边缘光滑</p>
          <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q4" label="1">是</el-radio>
          <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q4" label="0">否</el-radio>
        </div>
      </div>
    </div>
    <h3>验片试戴</h3>
    <div class="sr-content">
      <ul>
        <li></li>
        <li>戴镜视力</li>
        <li>居中性</li>
        <li>活动性</li>
        <li>荧光素染色</li>
      </ul>
      <ul>
        <li>OD</li>
        <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_VA_OD" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
        <li>
          <el-select v-model="postdata.S_OP_Json.CL_deliveryLens_Center_OD"  filterable allow-create  placeholder="请选择">
            <el-option
                v-for="item in jzx"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="postdata.S_OP_Json.CL_deliveryLens_Activity_OD"  filterable allow-create  placeholder="请选择">
            <el-option
                v-for="item in hyx"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="postdata.S_OP_Json.CL_deliveryLens_Dye_OD"  filterable allow-create  placeholder="请选择">
            <el-option
                v-for="item in ygsrs"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
      </ul>
      <ul>
        <li>OS</li>
        <li> <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_VA_OS" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input></li>
        <li>
          <el-select v-model="postdata.S_OP_Json.CL_deliveryLens_Center_OS"  filterable allow-create  placeholder="请选择">
            <el-option
                v-for="item in jzx"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="postdata.S_OP_Json.CL_deliveryLens_Activity_OS"  filterable allow-create  placeholder="请选择">
            <el-option
                v-for="item in hyx"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="postdata.S_OP_Json.CL_deliveryLens_Dye_OS"  filterable allow-create  placeholder="请选择">
            <el-option
                v-for="item in ygsrs"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
      </ul>
    </div>
    <div class="jl">
      <p>结论</p>
      <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q5" label="1">是</el-radio>
      <el-radio v-model="postdata.S_OP_Json.CL_deliveryLens_Q5" label="0">否</el-radio>
    </div>
    <div class="time">
      <p>定片时间</p>
      <el-date-picker
          class="rq mag-7"
          v-model="postdata.S_OP_Json.CL_deliveryLens_receiveDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
      <p>取镜时间</p>
      <el-date-picker
          @change="hh"
          class="rq"
          v-model="postdata.S_OP_Json.CL_deliveryLens_checkDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
      <p>验片人</p>
      <el-input v-model="postdata.S_OP_Json.CL_deliveryLens_checker" style="margin-right: 30px;"  class="sr" placeholder="请输入内容"></el-input>
    </div>
    <div class="db">
      <el-button style="margin-right: 50px;" @click="$router.back(-1)">返回</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "yanpian",
  data() {
    return {
      radio: '',
      times: '',
      postdata: {
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_ExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000",
        S_OP_HealthCheckId: '',
        S_OP_ID: '',
        S_OP_Model: "CL_deliveryLens210629",
        S_OP_Time: '',
        S_OP_Type: "CL_deliveryLens",
        S_OP_XML: '',
        S_OP_Json: {
          CL_deliveryLens_Activity_OD: '',
          CL_deliveryLens_Activity_OS: '',
          CL_deliveryLens_Center_OD: '',
          CL_deliveryLens_Center_OS: '',
          CL_deliveryLens_Color_OD: '',
          CL_deliveryLens_Color_OS: '',
          CL_deliveryLens_DCurve_OD: '',
          CL_deliveryLens_DCurve_OS: '',
          CL_deliveryLens_Degrade_OD: '',
          CL_deliveryLens_Degrade_OS: '',
          CL_deliveryLens_Dye_OD: '',
          CL_deliveryLens_Dye_OS: '',
          CL_deliveryLens_JiHu_OD: '',
          CL_deliveryLens_JiHu_OS: '',
          CL_deliveryLens_Q1: '',
          CL_deliveryLens_Q2: '',
          CL_deliveryLens_Q3: '',
          CL_deliveryLens_Q4: '',
          CL_deliveryLens_Q5: '',
          CL_deliveryLens_Thickness_OD: '',
          CL_deliveryLens_Thickness_OS: '',
          CL_deliveryLens_VA_OD: '',
          CL_deliveryLens_VA_OS: '',
          CL_deliveryLens_checkDate: '',
          CL_deliveryLens_checker: '',
          CL_deliveryLens_name: '',
          CL_deliveryLens_number: '',
          CL_deliveryLens_receiveDate: ''
        }
      },
      jzx: [
        {
          value: '是',
          label: '是'
        },
        {
          value: '否',
          label: '否'
        },
        {
          value: '',
          label: ''
        },
      ],
      hyx: [
        {
          value: '<1.0',
          label: '<1.0'
        },
        {
          value: '1.0-2.5',
          label: '1.0-2.5'
        },
        {
          value: '>2.5',
          label: '>2.5'
        },
        {
          value: '',
          label: ''
        },
      ],
      ygsrs: [
        {
          value: '正常',
          label: '正常'
        },
        {
          value: '松',
          label: '松'
        },
        {
          value: '紧',
          label: '紧'
        },
        {
          value: '水平偏位',
          label: '水平偏位'
        },
        {
          value: '',
          label: ''
        },
      ],
    }
  },
  created() {
    this._api.publicApi.PS_all_Normal_getList('CL_deliveryLens', '')
    .then(res => {
      // console.log(res)
      if (res) {
        this.postdata = JSON.parse(JSON.stringify(res[0]))
      }
    })
  },
  methods: {
    hh(e) {
      console.log(e)
    },
    tb() {
      let obj = this.$store.state.corneaContact.jqyptbsj
      this.postdata.S_OP_Json.CL_deliveryLens_Color_OD = obj.ysod
      this.postdata.S_OP_Json.CL_deliveryLens_Color_OS = obj.ysos
      this.postdata.S_OP_Json.CL_deliveryLens_Degrade_OD = obj.jfod
      this.postdata.S_OP_Json.CL_deliveryLens_Degrade_OS = obj.jfos
      this.postdata.S_OP_Json.CL_deliveryLens_DCurve_OD = obj.dwhod
      this.postdata.S_OP_Json.CL_deliveryLens_DCurve_OS = obj.dwhos
      // console.log(this.$store.state.corneaContact.jqyptbsj)
    },
    save() {
      this._api.publicApi.S_ALL_Normal_Save(this.postdata)
    }
  }
}
</script>

<style scoped lang="scss">
.yanpian {
  padding: 20px 30px;
  height: calc(87vh - 40px);
  overflow-y: auto;
}
.tops {
  display: flex;
  align-items: center;
  p {padding-right: 5px;}
  .xm {padding-left: 30px;}
  .sr {
    width: 140px;
  }
}
h3 {
  text-align: left;
  padding: 25px 0;
  font-size: 15px;
}
.sr-content {
  ul {
    display: flex;
    align-items: center;
    li{
      width: 120px;
      padding-right: 10px;
    }
    li:nth-child(1) {width: 2em;}
  }
  ul:nth-child(2) {padding-bottom: 10px;}
}
.dx {
  //display: flex;
  //align-items: center;
  div {
    display: flex;
    align-items: center;
    p {padding-right: 20px;}
  }
  div:nth-child(1) {padding-top: 20px;padding-bottom: 20px;}
}
.jl {
  display: flex;
  align-items: center;
  padding: 20px 0;
  p {
    padding-right: 20px;
  }
}
.time {
  display: flex;
  align-items: center;
  padding-top: 10px;
  p {padding-right: 10px;padding-left: 20px;}
  p:nth-child(1) {padding-left: 0 !important;}
  .sr {width: 10em}
}
.db {
  padding-top: 40px;
}
</style>
